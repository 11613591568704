import React from 'react';
import { useEffect, useState } from 'react';
import { getSingleContentByType } from '../contentService';

function Intro() {
    const [intro, setIntro] = useState({greetingText: '', greetingSubText: ''});

    useEffect(() => {
        getSingleContentByType('introContainer').then((res: any) => setIntro(res));
      }, []);


    return (
        <section id="intro" className="s-intro target-section">

        <div className="row intro-content">
            <div className="column large-9 mob-full intro-text">
                <h3>{intro?.greetingText}</h3>
                <h1>{intro?.greetingSubText}
                </h1>
            </div>

            <div className="intro-scroll">
                <a href="#about" className="intro-scroll-link smoothscroll">
                    Scroll For More
                </a>
            </div>

            <div className="intro-grid"></div>
            <div className="intro-pic"></div>

        </div>

    </section>
    );
}

export default Intro;

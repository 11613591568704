import React from 'react';
import { useEffect, useState } from 'react';
import { getSingleContentByType } from '../contentService';

function About() {
    const [about, setAbout] = useState({sectionTitle: '', text: '', skills: [{skillset: {title: '', text: ''}}]});

    useEffect(() => {
        getSingleContentByType('aboutContainer').then((res: any) => setAbout(res));
      }, []);


    return (
        <section id="about" className="s-about target-section">

            <div className="about-me">

                <div className="row heading-block" data-aos="fade-up">
                    <div className="column large-full">
                        <h2 className="section-heading">{about?.sectionTitle}</h2>
                    </div>
                </div>

                <div className="row about-me__content" data-aos="fade-up">
                    <div className="column large-full about-me__text">
                        <p className="lead">{about?.text}</p>
                    </div>
                </div>

            </div>

            <div className="row services-list block-large-1-3 block-medium-1-2 block-tab-full">

                { about?.skills?.map((sk: any, i: any) => {
                    return <div className="column item-service" data-aos="fade-up">
                        <div className="item-service__content">
                            <h4 className="item-title">{sk?.fields?.title}</h4>
                            <p>{sk?.fields?.text}</p>
                        </div>
                    </div>
                 })
                }

            </div>

        </section> 
    );
}

export default About;

import { client } from './contentfulClient';

export const getSingleContentByType = async (contentType: string) => {
    try {
        const entries = await client.getEntries({ content_type: contentType });
        return entries?.items[0]?.fields;
    }
    catch (error: any) {
        console.log('error fetching content', error);
    }
}

export const getPluralContentByType = async (contentType: string) => {
    try {
        const entries = await client.getEntries({ content_type: contentType });
        
        const filteredEntries = entries?.items?.map((data: any) => {
            const filteredEntry = data.fields;
            return filteredEntry;
        });

        return filteredEntries;
    }
    catch (error: any) {
        console.log('error fetching content', error);
    }
}
import React from 'react';
import './Header.css';
import { useEffect, useState } from 'react';
import { getPluralContentByType } from '../contentService';

function Header() {
    const [socialMedia, setSocialMedia] = useState([{link: '', socialMediaType: ''}]);

    useEffect(() => {
        getPluralContentByType('socialLink').then((res: any) => setSocialMedia(res.sort((s:any) => s?.desc)));
      }, []);

    const toggleMenu = () => {
      var menu = document.getElementById('responsive-header');
      // @ts-ignore
      menu.style.display === 'block' ? menu.style.display = 'none' : menu.style.display = 'block';
    }

    return (
      <header className="s-header">

      <nav className="header-nav-wrap" id='responsive-header'>
          <ul className="header-main-nav">
              <li className="current" onClick={toggleMenu}><a className="smoothscroll" href="#intro" title="intro">Intro</a></li>
              <li onClick={toggleMenu}><a className="smoothscroll" href="#about" title="about">About</a></li>
              <li onClick={toggleMenu}><a className="smoothscroll" href="#contact" title="contact us">Contact</a></li>	
          </ul>

            <ul className="header-social">
                { socialMedia?.map((sm: any, i: any) => {
                    return <li><a href={sm?.link} key={i} target='_blank' title={sm?.altText}>
                    <img className='social-media-icon' src={sm?.icon?.fields?.file?.url} alt={sm?.socialMediaType} />
                    </a></li>
                 })
                }
            </ul>
      </nav>
      <a className="header-menu-toggle" onClick={toggleMenu}><span>Menu</span></a>

    </header>
    );
}

export default Header;

import React from 'react';
import './Contact.css';

function Contact() {
    const [result, setResult] = React.useState("");

    const onSubmit = async (event: any) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "007a5999-a1b9-46d1-bd1f-fcce9711d655");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };


    return (
        <section id="contact" className="s-contact ss-dark target-section">

            <div className="row heading-block" data-aos="fade-up">
                <div className="column large-full">
                    <h2 className="section-heading">Contact</h2>
                    <div className='form-container'>
                        <form onSubmit={onSubmit}>
                            <input type="text" name="name" placeholder='Name' required/>
                            <input type="email" name="email" placeholder='Email' required/>
                            <textarea name="message" rows={6} placeholder='Message' required></textarea>
    
                            <button type='submit' className='contact-button' title='submit'>Submit</button>
                        </form>
                        <div className='form-log'>{result}</div>  
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Contact;
